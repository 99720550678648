import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";

SwiperCore.use([Navigation]);

const MainSectionBlog = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const kategorie = props.kategorie.map(
    (node) =>
      node.count && (
        <SwiperSlide key={node.slug}>
          <li className="blog-nav-item">
            {" "}
            <Link
              to={"/blog/" + node.slug}
              activeClassName="blog-nav-item-active"
            >
              {node.name}
            </Link>
          </li>
        </SwiperSlide>
      )
  );

  const handleSlideChange = () => {
    if (swiper) {
      const activeIndex = swiper.activeIndex;
      setActiveSlideIndex(activeIndex);
      localStorage.setItem("activeSlideIndex", activeIndex.toString());
    }
  };

  useEffect(() => {
    const storedIndex = localStorage.getItem("activeSlideIndex");
    if (storedIndex) {
      const index = parseInt(storedIndex, 10);
      setActiveSlideIndex(index);
      if (swiper) {
        swiper.slideTo(index);
      }
    }
  }, [swiper]);

  return (
    <section className="section-blog">
      <div className="banner-blog">
        <h1>Blog</h1>
        <h2>
          VZDELÁVAJTE SA <br style={{ display: "block" }} />a priblížte sa
          svojim zákazníkom
        </h2>
        <StaticImage
          className="arrow-down"
          src="../../images/new-web/arrow.svg"
          alt="Arrow"
          placeholder="blurred"
        />
      </div>
      <ul className="blog-nav">
        <Swiper
          slidesPerView={3}
          loop={false}
          direction={"horizontal"}
          className="swiper"
          breakpoints={{
            768: {
              slidesPerView: 6,
            },
          }}
          onSwiper={(swiper) => setSwiper(swiper)}
          onSlideChange={handleSlideChange}
          initialSlide={activeSlideIndex}
        >
          <SwiperSlide>
            <li className="blog-nav-item">
              {" "}
              <Link to={"/blog"} activeClassName="blog-nav-item-active">
                Všetky
              </Link>
            </li>
          </SwiperSlide>
          {kategorie}
        </Swiper>
      </ul>
    </section>
  );
};

export default MainSectionBlog;
